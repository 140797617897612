<template>
  <div class="my-3">
    <nav
      class="navbar navbar-expand-lg navbar-light border-0 bg-white items-center"
    >
      <div class="flex flex-col sm:flex-row gap-2 sm:items-center">
        <a class="d-lg-none text-blue-900 fs-18 font-bold mb-0" href="#">{{
          labelActive
        }}</a>

        <div class="d-lg-none" v-if="showing === 'test'">
          <button
            class="btn btn-sm radius-0 d-flex align-items-center bg-pri bd-pri text-white robo-18-500 mr-2"
            @click="changeType(7)"
          >
            <img
              src="../../../public/assets/images/icon/plus.svg"
              class="mr-2"
              style="height: 14px; width: 14px"
            />
            {{ $t("obj_health_records.lbl_add") }}
          </button>
        </div>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarHSSKDetail"
        aria-controls="navbarHSSKDetail"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse mt-2" id="navbarHSSKDetail">
        <ul class="navbar-nav mr-auto gap-2" style="margin-left: 0">
          <li
            class="nav-item p-2"
            :class="showing === 'overview' ? 'active d-none d-lg-flex' : ''"
          >
            <a
              href="javascript:;"
              :class="
                showing === 'overview'
                  ? 'robo-18-500  bd-pri txt-pri'
                  : 'robo-18-400 normal-type'
              "
              @click="changeType(1)"
            >
              {{ $t("obj_health_records.lbl_overview") }}
            </a>
          </li>
          <li
            class="nav-item p-2"
            :class="
              showing === 'health-information' ? 'active d-none d-lg-flex' : ''
            "
          >
            <a
              href="javascript:;"
              :class="
                showing === 'health-information'
                  ? 'robo-18-500  bd-pri txt-pri'
                  : 'robo-18-400 normal-type'
              "
              @click="changeType(2)"
            >
              {{ $t("obj_health_records.lbl_health_information") }}
              <img
                src="../../../public/assets/images/icon/icon_dot_red.svg"
                v-if="care_plan_sign && care_plan_sign.health_flag === 1"
                style="height: 12px; width: 12px"
              />
            </a>
          </li>
          <li
            class="nav-item p-2"
            :class="
              showing === 'health-information-listing'
                ? 'active d-none d-lg-flex'
                : ''
            "
          >
            <a
              href="javascript:;"
              :class="
                showing === 'heart-rate'
                  ? 'robo-18-500  bd-pri txt-pri'
                  : 'robo-18-400 normal-type'
              "
              :disabled="!disease ? true : false"
              @click="changeType(11)"
            >
              {{ $t("obj_health_records.lbl_heart_rate") }}
            </a>
          </li>
          <li
            class="nav-item p-2"
            :class="showing === 'test' ? 'active d-none d-lg-flex' : ''"
          >
            <a
              href="javascript:;"
              :class="
                showing === 'test'
                  ? 'robo-18-500  bd-pri txt-pri'
                  : 'robo-18-400 normal-type'
              "
              :disabled="!disease ? true : false"
              @click="changeType(3)"
            >
              {{ $t("obj_health_records.lbl_test_image") }}
              <img
                v-if="care_plan_sign && care_plan_sign.test_flag == 1"
                src="../../../public/assets/images/icon/icon_dot_red.svg"
                style="height: 12px; width: 12px"
              />
            </a>
          </li>
          <li
            class="nav-item p-2"
            :class="showing === 'medicines' ? 'active d-none d-lg-flex' : ''"
          >
            <a
              href="javascript:;"
              :class="
                showing === 'medicines' || showing === 'medicines-add'
                  ? 'robo-18-500  bd-pri txt-pri'
                  : 'robo-18-400 normal-type'
              "
              :disabled="!disease ? true : false"
              @click="changeType(4)"
            >
              {{ $t("obj_health_records.lbl_medicine") }}
              <img
                v-if="care_plan_sign && care_plan_sign.medicine_flag === 1"
                src="../../../public/assets/images/icon/icon_dot_red.svg"
                style="height: 12px; width: 12px"
              />
            </a>
          </li>
          <li
            class="nav-item p-2"
            :class="showing === 'todo' ? 'active d-none d-lg-flex' : ''"
          >
            <a
              href="javascript:;"
              :class="
                showing === 'todo'
                  ? 'robo-18-500  bd-pri txt-pri'
                  : 'robo-18-400 normal-type'
              "
              :disabled="!disease ? true : false"
              @click="changeType(10)"
            >
              {{ $t("obj_health_records.lbl_advice") }}
            </a>
          </li>
          <li
            class="nav-item p-2"
            :class="showing === 'diary' ? 'active d-none d-lg-flex' : ''"
          >
            <a
              href="javascript:;"
              :class="
                showing === 'diary'
                  ? 'robo-18-500  bd-pri txt-pri'
                  : 'robo-18-400 normal-type'
              "
              :disabled="!disease ? true : false"
              @click="changeType(5)"
            >
              {{ $t("obj_health_records.lbl_message") }}
              <img
                v-if="care_plan_sign && care_plan_sign.diary_flag === 1"
                src="../../../public/assets/images/icon/icon_dot_red.svg"
                style="height: 12px; width: 12px"
              />
            </a>
          </li>
          <li
            class="nav-item p-2"
            :class="showing === 'document' ? 'active d-none d-lg-flex' : ''"
          >
            <a
              href="javascript:;"
              :class="
                showing === 'document'
                  ? 'robo-18-500  bd-pri txt-pri'
                  : 'robo-18-400 normal-type'
              "
              :disabled="!disease ? true : false"
              @click="changeType(8)"
            >
              {{ $t("obj_health_records.lbl_original_document") }}
              <img
                v-if="care_plan_sign && care_plan_sign.document_flag === 1"
                src="../../../public/assets/images/icon/icon_dot_red.svg"
                style="height: 12px; width: 12px"
              />
            </a>
          </li>
          <li
            class="nav-item p-2"
            :class="
              showing === 'health-information-listing'
                ? 'active d-none d-lg-flex'
                : ''
            "
          >
            <a
              href="javascript:;"
              :class="
                showing === 'health-information-listing'
                  ? 'robo-18-500  bd-pri txt-pri'
                  : 'robo-18-400 normal-type'
              "
              :disabled="!disease ? true : false"
              @click="changeType(9)"
            >
              {{ $t("obj_health_records.lbl_other_information") }}
            </a>
          </li>
          <li v-if="showing === 'test'" class="nav-item d-none d-lg-flex">
            <div>
              <button
                class="btn btn-sm radius-0 d-flex align-items-center bg-pri bd-pri text-white robo-18-500 mr-2"
                @click="changeType(7)"
              >
                <img
                  src="../../../public/assets/images/icon/plus.svg"
                  class="mr-2"
                  style="height: 14px; width: 14px"
                />
                {{ $t("obj_health_records.lbl_add") }}
              </button>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'TypeBar',
  props: ['showing', 'person_id', 'disease'],
  data () {
    return {
      type: 1,
      care_plan_sign: null,
      care_plan_id: ''
    }
  },
  mounted () {
    let self = this
    self.getPersonsCareplanSign(true)
  },
  computed: {
    labelActive () {
      switch (this.showing) {
        case 'overview':
          return this.$t('obj_health_records.lbl_overview')
        case 'health-information':
          return this.$t('obj_health_records.lbl_health_information')
        case 'test':
          return this.$t('obj_health_records.lbl_test_image')
        case 'medicines':
        case 'medicines-add':
          return this.$t('obj_health_records.lbl_medicine')
        case 'todo':
          return this.$t('obj_health_records.lbl_advice')
        case 'diary':
          return this.$t('obj_health_records.lbl_message')
        case 'document':
          return this.$t('obj_health_records.lbl_original_document')
        case 'health-information-listing':
          return this.$t('obj_health_records.lbl_other_information')
        case 'heart-rate':
          return this.$t('Chỉ số nhịp tim')
        default:
          return this.$t('obj_health_records.lbl_overview')
      }
    }
  },
  methods: {
    changeType (type) {
      let self = this
      self.type = type
      if (self.disease) {
        if (self.type === 1) {
          self.$router
            .push({ path: `/doctor/diseases/${self.$route?.params?.id}` })
            .catch((_) => {})
        }
        if (self.type === 2) {
          if (self.care_plan_id) {
            self.putPersonsCareplanSign(
              self.care_plan_id,
              false,
              2,
              self.care_plan_sign
            )
          }
          self.$router
            .push({
              path: `/doctor/diseases/${self.$route?.params?.id}/health-information`
            })
            .catch((_) => {})
        }
        if (self.type === 3) {
          self.$router
            .push({ path: `/doctor/diseases/${self.disease.id}/test-info` })
            .catch((_) => {})
        }
        if (self.type === 4) {
          self.$router
            .push({ path: `/doctor/diseases/${self.disease.id}/prescription` })
            .catch((_) => {})
        }
        if (self.type === 5) {
          self.$router
            .push({ path: `/doctor/diseases/${self.disease.id}/activity` })
            .catch((_) => {})
        }
        // if (self.type === 6) {
        //   self.$router.push({ path: `/doctor/diseases/${self.disease.id}/medicine-add?person=${self.disease.person_id}` }).catch(_ => { })
        // }
        if (self.type === 7) {
          self.$router
            .push({
              path: `/doctor/diseases/${self.disease.id}/test-create-option`
            })
            .catch((_) => {})
        }
        if (self.type === 8) {
          self.$router
            .push({
              path: `/doctor/diseases/${self.disease.id}/document-management`
            })
            .catch((_) => {})
        }
        if (self.type === 9) {
          self.$router
            .push({
              path: `/doctor/diseases/${self.disease.id}/health-information-listing?person=${self.disease.person_id}`
            })
            .catch((_) => {})
        }
        if (self.type === 10) {
          self.$router
            .push({ path: `/doctor/diseases/${self.disease.id}/todo-list` })
            .catch((_) => {})
        }
        if (self.type === 11) {
          self.$router
            .push({ path: `/doctor/diseases/${self.disease.id}/heart-rate` })
            .catch((_) => {})
        }
      }
    },
    onShowModalUpload (show) {
      console.log('show')
      this.$emit('onShowModalUpload', show)
    },
    async getPersonsCareplanSign (update = false) {
      let self = this
      let showing = self.showing
      let type =
        showing === 'diary'
          ? 5
          : showing === 'overview'
            ? 1
            : showing === 'health-information'
              ? 2
              : showing === 'test'
                ? 3
                : showing === 'medicines'
                  ? 4
                  : showing === 'document'
                    ? 8
                    : showing === 'health-information-listing'
                      ? 6
                      : 1
      try {
        self.$rf
          .getRequest('DoctorRequest')
          .getPersonsCareplanSign(self.$route?.params?.id)
          .then((res) => {
            if (res && res.data) {
              self.care_plan_sign = res.data
              self.care_plan_id = self.care_plan_sign.id
              if (update) {
                self.putPersonsCareplanSign(
                  res?.data?.id,
                  !update,
                  type,
                  res?.data
                )
              }
            }
          })
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async putPersonsCareplanSign (id, refresh = false, type, sign) {
      let self = this
      if (!id) return
      try {
        var params = {}
        if (type === 2 && sign.health_flag === 1) {
          params.health_flag = 0
        }
        if (type === 3 && sign.test_flag === 1) {
          params.test_flag = 0
        }
        if (type === 4 && sign.medicine_flag === 1) {
          params.medicine_flag = 0
        }
        if (type === 5 && sign.diary_flag === 1) {
          params.diary_flag = 0
        }
        if (type === 8 && sign.document_flag === 1) {
          params.document_flag = 0
        }
        self.$rf
          .getRequest('DoctorRequest')
          .putPersonsCareplanSign(id, params)
          .then((res) => {
            if (refresh) self.getPersonsCareplanSign()
          })
      } catch (e) {
        // statements
        console.log(e)
      }
    }
  }
}
</script>
<style lang="css" scoped>
.normal-type {
  color: #536cbc;
}
.normal-type:hover {
  color: #20419b !important;
}
.active-border {
  border-bottom: solid 1.5px;
}
a:hover {
  text-decoration: none;
}
a[disabled] {
  /*pointer-events: none;*/
  cursor: not-allowed;
}
.nav-item.active a {
  color: #20419b !important;
}
button.inactive {
  background-color: #667085 !important;
  border-color: #667085 !important;
  color: white;
  opacity: 0.6;
  cursor: default;
}
</style>
